@import "compass";
@import "../../theme_variables";
@import "../../mixins/mixin";
/*******************************************************************
          novMEGAMENU STYLE
********************************************************************/
@media (min-width: 767px) {
  .nov-megamenu {
    text-align: center;
    .dropdown-menu {
      visibility: hidden;
      @include opacity(0);
      z-index: 100;
      background-color: #fff;
      display: none;
      padding: 0;
      margin: 0;
      border: none;
      @include border-radius(0);
      @include transition(all 0.3s ease);
    }

    .menu {
      margin: 0;
      padding: 0;
      > li {
        display: inline-block;
        list-style: none;
        //Menu leve1
        > a {
          display: block;
          font-family: $mainmenu-font-family;
          font-size: $mainmenu-font-size;
          font-weight: 400;
          text-transform: uppercase;
          @include rtl-padding(17px,19px,17px,18px);
          line-height: 100%;
          background-color: transparent;
          color: $mainmenu-link-color;
          i {
            font-size: 15px;
            @include rtl-margin-right(7px);
          }
        }
        //Icon has child menu
        &.group, &.has-sub {
            position: relative;
            > a {
                /* &:before {
                    display: inline-block;
                    content: "\f107";
                    font-family: "FontAwesome";
                    top: 0;
                    position: relative;
                    font-size: 1.4rem;
                    @include rtl-float-right();
                    @include rtl-margin-left(5px);
                } */
             }
        }

        //Menu active lever 1
        &.active {
          > a {
            color: $mainmenu-link-hover-color;
            &:hover {

            }
          } 
        }
        // Menu when hover lever1
        &:focus,
        &:hover {
          color: $mainmenu-link-hover-color;
          > a {
            color: $mainmenu-link-hover-color;
            &:focus,
            &:hover {
              @include transition(all 0.3s ease);
              color: $mainmenu-link-hover-color;
            } 
          }
          > .dropdown-menu{
            @include opacity(1);
            visibility: visible;
            display: block;
          }
        }
        //Menu title
        .menu-title {
          text-transform: uppercase;
          color: $theme-color-default;
          font-weight: 700;
          font-family: $font-family-primary;
          font-size: 1.6rem;
          margin-bottom: 10px;
          padding-bottom: 15px;
          border-bottom: 1px dotted $base-border-color;
          display: block;
        }
        

        //Submenu
        &.has-sub {
          > .dropdown-menu {
            @include box-shadow(0 5px 7px rgba(#000,0.35));
            li.has-sub {position: relative;}
            ul {
              padding: 0;
              @include rtl-text-align-left();
              li {
                display: block;
                border-top: 1px dotted $base-border-color;
                a {
                  @include rtl-padding(9px, 5px, 9px, 20px);
                  display: block;
                  font-size: 1.2rem;
                  color: $mainsubmenu-link-color;
                }
                &:first-child {
                  border-top: 0;
                }
                &:hover {
                  > a {
                    color: $theme-color-primary;
                  }
                }
              }
            }
            .dropdown-menu {
              position: absolute;
              @include rtl-left(100%);
              top: 0;
              padding: 0;
              margin: 0;
              border: 0;
              @include rtl-border-left(5px solid transparent);
              @include border-radius(0);
            }
            li:hover {
              > .dropdown-menu {
                @include opacity(1);
                visibility: visible;
                display: block;
              }
            }
          }
        }
        &.group {
          > .dropdown-menu {
            padding: 3.5rem 0 0;
            @include box-shadow(0 5px 7px rgba(#000,0.35));
            .dropdown-menu {
              visibility: visible;
              @include opacity(1);
              position: relative;
              @include rtl-left(inherit);
              top: inherit;
              float: none;
              background: transparent;
              font-size: 1.4rem;
              color: #666;
              display: block;
              > ul {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-flex-wrap: wrap;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin-right: -15px;
                margin-left: -15px;
              }
            }
          }
          ul {
            padding: 0;
            list-style: none;
          }

          //menu-content
          .menu-content {
            ul {
              li {
                margin-bottom: 5px;
                a {
                  color: #666;
                  &:hover {
                    color: $theme-color-primary;
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
              margin-bottom: 3.5rem;
            }
            .sale-off {
              .menu-title {
                color: #ff3a3a;
              }
              a {
                color: #ff3a3a;
              }
            }
            .d-flex {
              margin-right: -5px;
              margin-left: -5px;
              .col {
                padding-left: 5px;
                padding-right: 5px;
              }
            }
          }

          &.background-women {
            > .dropdown-menu {
              min-height: 452px;
              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-image: url(../../../img/background-women.jpg);
                background-position: center bottom;
                background-repeat: no-repeat;
                background-size: cover;
              }
              .lang-rtl & {
                &:before {
                  -webkit-transform: rotateY(180deg);
                     -moz-transform: rotateY(180deg);
                      -ms-transform: rotateY(180deg);
                       -o-transform: rotateY(180deg);
                          transform: rotateY(180deg);
                }
              }
            }
          }

        }

        &.full-width {
          position: static;
          > .dropdown-menu {
            width: 100% !important;
          }
        }
        &.align-left {
          position: relative;
          > .dropdown-menu {
            @include rtl-right(0 !important);          }
        }
        &.align-right {
          position: relative;
          > .dropdown-menu {
            @include rtl-right(0 !important);
          }
        }
        &.flipback {
          > .dropdown-menu {
            right: 0;
            left: inherit;
          }
        }
      }
    }

    .product-image-container {
      a {
         padding: 0px;
      }
    }
  }

  #nov-megamenu {
    height: 48px;
    @include transition(all 0.3s ease);
    &.nov-megamenu--fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
      background: #f1f6f8;
      @include box-shadow(0px 5px 5px -5px #999);
      @include transition(top,height,background 0.3s ease);
    }
  }
  .header-4 {
    #nov-megamenu {
      &.nov-megamenu--fixed {
        background: #222;
      }
    }
  }
}

@media (max-width: 767px) {
  span.opener {
    position: absolute;
    @include rtl-right(0);
    z-index: 1;
    padding: 10px;
    display: block;
    top: -2px;
    cursor: pointer;
    &:before {
      content: '\f278';
      font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
      font-size: 15px;
      text-rendering: auto;
    }
  }
  .menu-active {
    > span.opener {
      &:before {
        content: '\f273';
      }
    }
  }
  #nov-megamenu {
    .dropdown-menu {
      position: relative;
      width: 100% !important;
      top: inherit;
      float: none;
      @include rtl-left(inherit);
      border: none;
      padding: 0;
      ul {
        padding: 0;
        list-style: none;
      }
    }
    .nov-megamenu {
      .menu {
        @include rtl-padding-left(0);
        margin-bottom: 0;
        list-style: none;
        .menu-title {
          padding: 8px 0;
          text-transform: uppercase;
          font-size: 1.4rem;
        }
        [class*=" col-"] {
          padding-left: 0;
          padding-right: 0;
        }
        > li {
          position: relative;
          padding: 8px 0;
          border-bottom: 1px dotted $base-border-color;
          display: block;
          font-size: 11px;
          > a {
            font-family: $font-family-primary;
            text-transform: uppercase;
            font-weight: 400;
            i {
              font-size: 15px;
              @include rtl-margin-right(10px);
              min-width: 20px;
              text-align: center;
            }
          }
          a {
            position: relative;
            z-index: 2;
          }
          li {
            padding: 8px 0;
            border-bottom: 1px dotted $base-border-color;
            font-size: 11px;
          }
        }
        li.has-sub, li.group {
          position: relative;
          > .dropdown-menu {
            > ul {
              @include rtl-padding-left(30px);
              padding-top: 10px;
              > li {
                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
          .group {
            border-bottom: none;
            padding: 0;
            margin-bottom: 10px;
            > .dropdown-menu {
              > ul {
                @include rtl-padding-left(0);
                padding-top: 0px;
                li {
                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }

      }
    }
  }
}

/******************
 * Vinova Themes  Framework for Prestashop 1.7.x 
 * @package    Nova - PrestaShop 1.7 Theme For Fashion Templates
 * @version    1.0
 * @author    http://vinovathemes.com/
 * @copyright  Copyright (C) October 2013 vinovathemes.com <@emai:vinovathemes@gmail.com>
 * <info@vinovathemes.com>.All rights reserved.
 * @license   GNU General Public License version 1
 * *****************/
@mixin sub-heading {
  color: $base-heading-color;
  text-transform: uppercase;
  font-family: $font-family-primary;
  font-weight: 600;
  font-size: $font-size-base + 2 ;
  line-height: 20px;
  padding: 14px 20px 17px;
  margin: 0 0 20px;
  position: relative;
  border: 1px solid $base-border-color;
  border-bottom: none;
  background: $base-box-bg;
}


@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
     -moz-#{$property}: #{$value};
      -ms-#{$property}: #{$value};
       -o-#{$property}: #{$value};
          #{$property}: #{$value};
}

@mixin gradient-horizontal($start-color: #1e9e6d, $end-color: #c8be54, $start-percent: 30%, $end-percent: 70%) {
  background-image: -webkit-linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Opera 12
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}
@mixin border-radius($radius: 5px) {
    @include css3-prefix('border-radius', $radius);
}

@mixin box-sizing($type: border-box) {
  @include css3-prefix('box-sizing', $type);
}

@mixin box-shadow($value) {
    @include css3-prefix('box-shadow', $value);
}


@mixin transition($properties...) {

  @if length($properties) >= 1 {
    @include css3-prefix('transition', $properties);
  }

  @else {
    @include css3-prefix('transition',  "all 0.2s ease-in-out 0s");
  }
}
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4)) {
    text-shadow: $x $y $blur $color;
}

@mixin opacity($opacity: 0.5) {
    $opacityMultiplied: ($opacity * 100);

    filter:         alpha(opacity=$opacityMultiplied);
    -ms-filter:     "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacityMultiplied + ")";
    @include css3-prefix('opacity', $opacity);
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin square ($x) {
  width: $x;
  height: $x;
}
@mixin size ($x, $y) {
  width: $x;
  height: $y;
}

@mixin border-after-right($varible: 8px){
  content: "";
  width: 1px;
  color: #d0d0d0;
  background-color: #d0d0d0;
  top: 0;
  right: 0;
  position: absolute;
}
$rem-baseline: 10px !default;
$rem-fallback: true !default;
$rem-px-only: false !default;
@mixin border-arrow-top ($width, $height, $color){
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 $width/2 $height $width/2;
  border-color: transparent transparent $color transparent;
}
@mixin border-arrow-bottom ($width, $height, $color){
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $height $width/2 0 $width/2;
  border-color: $color transparent transparent transparent;
}
@mixin border-arrow-right ($width, $height, $color){
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $height/2 0 $height/2 $width;
  border-color: transparent transparent transparent $color;
}
@mixin border-arrow-left ($width, $height, $color){
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $height/2 $width $height/2 0;
  border-color: transparent $color transparent transparent;
}
@function rem-separator($list, $separator: false) {
  @if $separator == "comma" or $separator == "space" {
    @return append($list, null, $separator);
  } 
  
  @if function-exists("list-separator") == true {
    @return list-separator($list);
  }

  // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);
  
  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
      $result: append($result, $value / 1rem * $rem-baseline + 0px, $separator);
    } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
      $result: append($result, $value / $rem-baseline + 0rem, $separator);
    } @else if type-of($value) == "list" {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == "map" {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}

/* BOOTSTRAP 4 */
@mixin display($value) {
// flex | inline-flex
  @if $value == "flex" {
    // 2009
    display: -webkit-box;
    display: -moz-box;
    display: box;

    // 2012
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox; // 2011 (IE 10)
    display: flex;
  } @else if $value == "inline-flex" {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: inline-box;

    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  } @else {
    display: $value;
  }
}

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-up($lower, $breakpoints) {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-between($name, $name, $breakpoints) {
    @content;
  }
}


/* RIGHT TO LEFT */
// BASIC CONVERTER (ignore these)
@mixin rtl-base-simple ($property, $direction) {
  #{$property}:$direction;
  .lang-rtl & {
    @if $direction == $rtl-right {
      #{$property}:$rtl-left;
    }
    @else {
      #{$property}:$rtl-right;
    }
  }
}
@mixin rtl-base-leftright ($property, $direction1, $direction2, $value1, $value2) {
  #{$property}-#{$direction1}: $value1;
  #{$property}-#{$direction2}: $value2;
  .lang-rtl & {
    @if $direction2 == $rtl-right {
      #{$property}-#{$rtl-left}: $value2;
    }
    @if $direction1 == $rtl-left {
      #{$property}-#{$rtl-right}: $value1;
    }
  }
}
@mixin rtl-base-inherit ($property, $direction, $value, $inherit : inherit) {
  #{$property}-#{$direction}: $value;
  .lang-rtl & {
    @if $direction == $rtl-right {
      #{$property}-#{$rtl-left}: $value;
    }
    @else {
      #{$property}-#{$rtl-right}: $value;
    }
    #{$property}-#{$direction}: $inherit;
  }
}

@mixin rtl-base-toprightbottomleft ($property, $t, $r, $b, $l) {
  #{$property}: $t $r $b $l;
  .lang-rtl & {
    #{$property}: $t $l $b $r;
  }
}

// BODY STYLES

@mixin rtl-direction ($forBody : true) {
  direction: ltr;
  @if $forBody {
    &.lang-rtl {
      direction: rtl;
    }
  }
  @else {
    .lang-rtl & {
      direction: rtl;
    }
  }
}

@mixin rtl-font-family ($ltr, $rtl, $forBody : false) {
  font-family: $ltr;
  @if $forBody {
    &.lang-rtl, &.non-latin {
      font-family:$rtl;
    }
  }
  @else {
    .lang-rtl &, .non-latin & {
      font-family:$rtl;
    }
  }
}


// MARGIN

@mixin rtl-margin ($t, $r, $b, $l) {
  @include rtl-base-toprightbottomleft(margin,$t, $r, $b, $l);
}
@mixin rtl-margin-lr ($value1, $value2) {
  @include rtl-base-leftright(margin,$rtl-left, $rtl-right, $value1, $value2);
}
@mixin rtl-margin-left ($value) {
  @include rtl-base-inherit(margin,$rtl-left,$value);
}
@mixin rtl-margin-right ($value) {
  @include rtl-base-inherit(margin,$rtl-right,$value);
}

// PADDING

@mixin rtl-padding ($t, $r, $b, $l) {
  @include rtl-base-toprightbottomleft(padding,$t, $r, $b, $l);
}
@mixin rtl-padding-lr ($value1, $value2) {
  @include rtl-base-leftright(padding,$rtl-left, $rtl-right, $value1, $value2);
}
@mixin rtl-padding-left ($value) {
  @include rtl-base-inherit(padding,$rtl-left,$value);
}
@mixin rtl-padding-right ($value) {
  @include rtl-base-inherit(padding,$rtl-right,$value);
}

// BORDER

@mixin rtl-border-left ($value) {
  @include rtl-base-inherit(border,$rtl-left,$value);
}
@mixin rtl-border-right ($value) {
  @include rtl-base-inherit(border,$rtl-right,$value);
}

// POSITION

@mixin rtl-left ($value) {
  #{$rtl-left}: $value;
  .lang-rtl & {
    #{$rtl-right}: $value;
    #{$rtl-left}: auto;
  }
}
@mixin rtl-right ($value) {
  #{$rtl-right}: $value;
  .lang-rtl & {
    #{$rtl-left}: $value;
    #{$rtl-right}: auto;
  }
}

// TEXT-ALIGN

@mixin rtl-text-align-left () {
  @include rtl-base-simple(text-align, $rtl-left);
}
@mixin rtl-text-align-right () {
  @include rtl-base-simple(text-align, $rtl-right);
}

// FLOAT

@mixin rtl-float-left () {
  @include rtl-base-simple(float, $rtl-left);
}
@mixin rtl-float-right () {
  @include rtl-base-simple(float, $rtl-right);
}
@mixin rtl-clear-left () {
  @include rtl-base-simple(clear, $rtl-left);
}
@mixin rtl-clear-right () {
  @include rtl-base-simple(clear, $rtl-right);
}


// BACKGROUND-POSITION

@mixin rtl-background-position-left ($vertical) {
  background-position:$rtl-left $vertical;
  .lang-rtl & {
    background-position:$rtl-right $vertical;
  }
}
@mixin rtl-background-position-right ($vertical) {
  background-position:$rtl-right $vertical;
  .lang-rtl & {
    background-position:$rtl-left $vertical;
  }
}

@mixin rtl-background-position-percent ($vertical, $horPercent) {
  background-position:$horPercent $vertical;
  .lang-rtl & {
    background-position:100% - $horPercent $vertical;
  }
}

// TEXT-SHADOW & BOX-SHADOW

@mixin rtl-text-shadow ($x, $rest) {
  text-shadow: $x $rest;
  .lang-rtl & {
    text-shadow: -1 * $x $rest;
  }
}
@mixin rtl-box-shadow ($x, $rest) {
  -moz-box-shadow: $x $rest;
  -webkit-box-shadow: $x $rest;
  box-shadow: $x $rest;
  .lang-rtl & {
    -moz-box-shadow: -1 * $x $rest;
    -webkit-box-shadow: -1 * $x $rest;
    box-shadow: -1 * $x $rest;
  }
}

// BORDER-RADIUS

@mixin rtl-border-radius-topright ($value) {
  -moz-border-radius-top#{$rtl-right}: $value;
  -webkit-border-top-#{$rtl-right}-radius: $value;
  border-top-#{$rtl-right}-radius: $value;
  .lang-rtl & {
    -moz-border-radius-top#{$rtl-left}: $value;
    -webkit-border-top-#{$rtl-left}-radius: $value;
    border-top-#{$rtl-left}-radius: $value;
    -moz-border-radius-top#{$rtl-right}: inherit;
    -webkit-border-top-#{$rtl-right}-radius: inherit;
    border-top-#{$rtl-right}-radius: inherit;
  }
}

@mixin rtl-border-radius-bottomright ($value) {
  -moz-border-radius-bottom#{$rtl-right}: $value;
  -webkit-border-bottom-#{$rtl-right}-radius: $value;
  border-bottom-#{$rtl-right}-radius: $value;
  .lang-rtl & {
    -moz-border-radius-bottom#{$rtl-left}: $value;
    -webkit-border-bottom-#{$rtl-left}-radius: $value;
    border-bottom-#{$rtl-left}-radius: $value;
    -moz-border-radius-bottom#{$rtl-right}: inherit;
    -webkit-border-bottom-#{$rtl-right}-radius: inherit;
    border-bottom-#{$rtl-right}-radius: inherit;
  }
}

@mixin rtl-border-radius-topleft ($value) {
  -moz-border-radius-top#{$rtl-left}: $value;
  -webkit-border-top-#{$rtl-left}-radius: $value;
  border-top-#{$rtl-left}-radius: $value;
  .lang-rtl & {
    -moz-border-radius-top#{$rtl-right}: $value;
    -webkit-border-top-#{$rtl-right}-radius: $value;
    border-top-#{$rtl-right}-radius: $value;
    -moz-border-radius-top#{$rtl-left}: inherit;
    -webkit-border-top-#{$rtl-left}-radius: inherit;
    border-top-#{$rtl-left}-radius: inherit;
  }
}

@mixin rtl-border-radius-bottomleft ($value) {
  -moz-border-radius-bottom#{$rtl-left}: $value;
  -webkit-border-bottom-#{$rtl-left}-radius: $value;
  border-bottom-#{$rtl-left}-radius: $value;
  .lang-rtl & {
    -moz-border-radius-bottom#{$rtl-right}: $value;
    -webkit-border-bottom-#{$rtl-right}-radius: $value;
    border-bottom-#{$rtl-right}-radius: $value;
    -moz-border-radius-bottom#{$rtl-left}: inherit;
    -webkit-border-bottom-#{$rtl-left}-radius: inherit;
    border-bottom-#{$rtl-left}-radius: inherit;
  }
}

//rotate
@mixin rtl-rotate ($value) {
  @include rotate($value);
  .lang-rtl & {
    @include rotate(-$value);
  }
}
//rotate 180
@mixin rtl-rotate-180 ($value) {
  @include rotateY($value);
  .lang-rtl & {
    @include rotateY($value - 180deg);
  }
}

// BORDER-RADIUS

@mixin rtl-border-radius-total($tl, $tr, $br, $bl) {
  -moz-border-radius: $tl, $tr, $br, $bl;
  -webkit-border-radius: $tl, $tr, $br, $bl;
  border-top-radius: $tl, $tr, $br, $bl;
  .lang-rtl & {
    -moz-border-radius: $tr, $tl, $bl, $br;
    -webkit-border-radius: $tr, $tl, $bl, $br;
    border-top-radius: $tr, $tl, $bl, $br;
  }
}

@mixin rtl-border-radius-topright ($value) {
  -moz-border-radius-top#{$rtl-right}: $value;
  -webkit-border-top-#{$rtl-right}-radius: $value;
  border-top-#{$rtl-right}-radius: $value;
  .lang-rtl & {
    -moz-border-radius-top#{$rtl-left}: $value;
    -webkit-border-top-#{$rtl-left}-radius: $value;
    border-top-#{$rtl-left}-radius: $value;
    -moz-border-radius-top#{$rtl-right}: inherit;
    -webkit-border-top-#{$rtl-right}-radius: inherit;
    border-top-#{$rtl-right}-radius: inherit;
  }
}

@mixin rtl-border-radius-bottomright ($value) {
  -moz-border-radius-bottom#{$rtl-right}: $value;
  -webkit-border-bottom-#{$rtl-right}-radius: $value;
  border-bottom-#{$rtl-right}-radius: $value;
  .lang-rtl & {
    -moz-border-radius-bottom#{$rtl-left}: $value;
    -webkit-border-bottom-#{$rtl-left}-radius: $value;
    border-bottom-#{$rtl-left}-radius: $value;
    -moz-border-radius-bottom#{$rtl-right}: inherit;
    -webkit-border-bottom-#{$rtl-right}-radius: inherit;
    border-bottom-#{$rtl-right}-radius: inherit;
  }
}

@mixin rtl-border-radius-topleft ($value) {
  -moz-border-radius-top#{$rtl-left}: $value;
  -webkit-border-top-#{$rtl-left}-radius: $value;
  border-top-#{$rtl-left}-radius: $value;
  .lang-rtl & {
    -moz-border-radius-top#{$rtl-right}: $value;
    -webkit-border-top-#{$rtl-right}-radius: $value;
    border-top-#{$rtl-right}-radius: $value;
    -moz-border-radius-top#{$rtl-left}: inherit;
    -webkit-border-top-#{$rtl-left}-radius: inherit;
    border-top-#{$rtl-left}-radius: inherit;
  }
}

@mixin rtl-border-radius-bottomleft ($value) {
  -moz-border-radius-bottom#{$rtl-left}: $value;
  -webkit-border-bottom-#{$rtl-left}-radius: $value;
  border-bottom-#{$rtl-left}-radius: $value;
  .lang-rtl & {
    -moz-border-radius-bottom#{$rtl-right}: $value;
    -webkit-border-bottom-#{$rtl-right}-radius: $value;
    border-bottom-#{$rtl-right}-radius: $value;
    -moz-border-radius-bottom#{$rtl-left}: inherit;
    -webkit-border-bottom-#{$rtl-left}-radius: inherit;
    border-bottom-#{$rtl-left}-radius: inherit;
  }
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x,$y);
      -ms-transform: translate($x,$y); // IE9+
          transform: translate($x,$y);
}
@mixin translateX($x) {
  -webkit-transform: translateX($x);
      -ms-transform: translateX($x); // IE9+
          transform: translateX($x);
  .lang-rtl & {
    -webkit-transform: translateX(-$x);
      -ms-transform: translateX(-$x); // IE9+
          transform: translateX(-$x);
  }
}
@mixin translateY($y) {
  -webkit-transform: translateY($y);
      -ms-transform: translateY($y); // IE9+
          transform: translateY($y);
}
@mixin rotate3d($vector-x,$vector-y,$vector-z,$rotate) {
  -webkit-transform: rotate3d($vector-x, $vector-y, $vector-z, $rotate);
      -ms-transform: rotate3d($vector-x, $vector-y, $vector-z, $rotate);
          transform: rotate3d($vector-x, $vector-y, $vector-z, $rotate);
}
@mixin scale($x,$y) {
-webkit-transform: scale($x,$y);
      -ms-transform: scale($x,$y);
          transform: scale($x,$y);
}
@mixin rotate($y) {
  -webkit-transform: rotate($y);
      -ms-transform: rotate($y); // IE9+
          transform: rotate($y);
}
@mixin appearance($value) {
   -webkit-appearance: $value;
   -moz-appearance:    $value;
   appearance:         $value;
}
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}
@mixin align-content($align) {
  -webkit-align-content: $align;
     -moz-align-content: $align;
      -ms-align-content: $align;
          align-content: $align;
}
@mixin align-items($align) {
  -webkit-align-items: $align;
     -moz-align-items: $align;
      -ms-align-items: $align;
          align-items: $align;
}
@mixin align-self($align) {
  -webkit-align-self: $align;
     -moz-align-self: $align;
      -ms-align-self: $align;
          align-self: $align;
}
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
     -moz-flex-basis: $width;
      -ms-flex-basis: $width;
          flex-basis: $width;
}
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
     -moz-flex-shrink: $shrink;
      -ms-flex-shrink: $shrink;
          flex-shrink: $shrink;
}
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
     -moz-flex-grow: $grow;
      -ms-flex-grow: $grow;
          flex-grow: $grow;
}
@mixin order($val) {
  -webkit-box-ordinal-group: $val;  
     -moz-box-ordinal-group: $val;     
  	     -ms-flex-order: $val;     
  	      -webkit-order: $val;  
  		      order: $val;
}
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
     -moz-flex-flow: $flow;
      -ms-flex-flow: $flow;
          flex-flow: $flow;
}
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
     -moz-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
}
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
     -moz-box-flex: $values;
      -webkit-flex: $values;
  	  -ms-flex: $values;
  	      flex: $values;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
     -moz-flex-direction: $direction;
      -ms-flex-direction: $direction;
          flex-direction: $direction;
}

//ANIMATION
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}